.title {
  width: fit-content;
  min-height: fit-content;
  padding-bottom: 1rem;
  font-size: 5rem;
}

.gradientUp {
  width: 100%;
  height: 10rem;
  margin-top: -10rem;
  z-index: 20;

  @mixin light {
    background: linear-gradient(0deg, var(--mantine-color-gray-0), transparent);
  }
  @mixin dark {
    background: linear-gradient(0deg, var(--mantine-color-dark-8), transparent);
  }
}

.ascents {
  height: 100%;
  flex-direction: column;
}
